<script lang="ts" setup>
  import { onClickOutside } from '@vueuse/core';
  import VButton from '~/components/ui/VButton.vue';
  import VProfileMenu from '~/components/header/VProfileMenu.vue';
  import NotificationBtn from '~/components/header/NotificationBtn.vue';

  const menuIsVisible = ref(false);
  const profileMenu = ref(null);
  const profileButton = ref(null);
  const { afterEach } = useRouter();
  const { state } = storeToRefs(useAuthStore());
  const { onReveal } = useAuth();

  onClickOutside(profileMenu, () => (menuIsVisible.value = false), { ignore: [profileButton] });
  const toggleMenu = () => (menuIsVisible.value = !menuIsVisible.value);

  afterEach(() => {
    if (menuIsVisible.value) toggleMenu();
  });
</script>

<template>
  <div class="profile">
    <template v-if="!state.loggedIn">
      <v-button class="profile__button" color="white" size="normal" to="/subscriptions#subscription-promocode">
        Промокод
      </v-button>
      <v-button class="profile__button" appearance="outline" size="normal" color="white" @click="onReveal()">
        Вход
      </v-button>
    </template>
    <template v-else>
      <notification-btn />
      <div class="profile__user">
        <v-user ref="profileButton" type="avatar" media="small" :active="menuIsVisible" @click="toggleMenu" />
        <v-profile-menu v-if="menuIsVisible" ref="profileMenu" use-image-loader class="profile__user-menu" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .profile {
    display: flex;
    gap: 16px;
    &__login {
      display: flex;
      gap: 16px;
    }
    &__user {
      position: relative;
    }
    &__user-menu {
      position: absolute;
      top: 56px;
      right: 0;
      @media (max-height: 620px) {
        height: calc(100vh - 80px);
        overflow-y: auto;
      }
    }
    &__button {
      min-width: 132px;
    }
    @media (max-width: $retina) {
      display: none;
    }
  }
</style>
