import { default as index01LyTremWbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexhSBk4s5YlZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/index.ts?macro=true";
import { default as PageFilters9D3NMAzg4iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriesf3yH8jhI1VMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListUmdSE2n32WMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersRjzDOn0Ne8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenreswi3VQEFilwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesp7j9QzlVSGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularHcqUH4l3tiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeriesp73lYGyyKtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersKmSj5jMfRgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenres4EysgSctb6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93hAv8QYTuQQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexrBw31c1r5gMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93uOWEvfbzKbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/collections/[slug].vue?macro=true";
import { default as indexK5JgfE6J2VMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/collections/index.vue?macro=true";
import { default as copyrightRF4KRayTIrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/copyright.vue?macro=true";
import { default as _91id_93M5cx6A40mNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/crew/[id].vue?macro=true";
import { default as indexpn69kIsaw1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/faq/index.vue?macro=true";
import { default as allXWl2b8kQupMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtT2FkqnpZksMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueiEom5bFPiFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesrSgDcAjsozMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedPdQV8vtt3jMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continue2LXFKNR5qgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/continue.vue?macro=true";
import { default as indexnJn84TrFtbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/index.vue?macro=true";
import { default as purchasedM8lIfLIxD3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedrLJ4zpNLmkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policyF7Oj8VnkNkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesVjz0YTQuH5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannerx4gUD1jXP8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsCTb9v7RkCMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueMlYVNSB8vGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilms1xA7UKkIrGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenrespSInlXwSR9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeader56yfHj21UwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularcxA7ybpsVgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersZgAslKe80IMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeries0hycjrHv3YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsfypIW9WdpyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarize4pYspmqiBTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionTopMovies3TJZU47EHuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionTopMovies.vue?macro=true";
import { default as SectionUpcomingEW9gKRt6H0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexZo2gpHMb0QMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93ei2H6x1LUGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallHfrlC8mwxFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallAPGo7WKGLxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typeslXqIixt1IAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/types.ts?macro=true";
import { default as useInstallzTvd4CtUxZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewTXQPLd2IYLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexNQxlNbl7SwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsL79z9opMK4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewS6RUwt2IHjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescription5MMXVHwuTlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeader8rLYfZ7MHsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeNbZRQoZqdzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonbKTi82WWgOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtont8y1v4MuGdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedfPYsZhGNruMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesSv5pjGEuwAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailerszU5GN1OKBGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptions2M9guFSnBcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93WZJoU23rdlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/news/[slug].vue?macro=true";
import { default as indexIFb9X6ns8hMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsgKrOW24EY7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesSYae5TEhHGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementgm8FPra1JDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightv3DoKWoFktMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holder7MUJ45WnA5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateENgCBhTwTkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-private.vue?macro=true";
import { default as policy_45userKdabhgqR5LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-user.vue?macro=true";
import { default as premiersFGlBJEdXD2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/premiers.vue?macro=true";
import { default as private_45policy8grXkUzbbVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/private-policy.vue?macro=true";
import { default as indexQzzcQGRuF3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalicBlmcaFq1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfile7XLw1Pa4idMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalcfNLs1snK0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDataEE7o9Ws92UMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModali5Yr27cQFoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerHSthlU1DyLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validation8eP1cR3jvAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryhnpa7HWHxEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardyxBy1fqomRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsJq3mns87yGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentse650Oi70vcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsAAgSAEUZnDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgram2ASOoGQy19Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsi9TzCJDkQJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexIRB0Ct2syzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/devices/index.vue?macro=true";
import { default as index0142KNfzIFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/index.vue?macro=true";
import { default as indexLBdslL14YiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexisemkdGQ8yMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/payments/index.vue?macro=true";
import { default as indexXaa10bC0FfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/settings/index.vue?macro=true";
import { default as indexGqwZbzs9X2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/user/index.vue?macro=true";
import { default as resultDIAAzFjThvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/result.vue?macro=true";
import { default as _91slug_93muUuVWufZXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/similar/[slug].vue?macro=true";
import { default as index5GcrrdRfTJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/sports/channels/index.vue?macro=true";
import { default as indexeImkAVZN47Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/sports/index.vue?macro=true";
import { default as SectionAds1bMPo3GBE5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentFgDPIBJd7GMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesyd5zTs5fEmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadSijrcM3OJoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeader8hcbCghohBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsMBTjsQ2g7HMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverqygxlXykh2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as index7Dl3hepeBtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/index.vue?macro=true";
import { default as termsFaMvbIbMEtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/terms.vue?macro=true";
import { default as upcomingQC5jr892NHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionTopMovies",
    path: "/home/components/SectionTopMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionTopMovies.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexQzzcQGRuF3Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexIRB0Ct2syzMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: index0142KNfzIFMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexLBdslL14YiMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexisemkdGQ8yMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexXaa10bC0FfMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: indexGqwZbzs9X2Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexeImkAVZN47Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-356332/src/pages/upcoming.vue").then(m => m.default || m)
  }
]