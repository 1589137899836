<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import { getTopMovies } from '~/services/modules/movies.service';
  import type { TBrowseTitle } from '~/types/common';
  import IconLightning from '~/assets/colorful/lightning.svg?skipsvgo';
  defineProps<TBrowseTitle>();

  const { data, isFetching, suspense } = getTopMovies();
  onServerPrefetch(async () => await suspense());
</script>
<template>
  <v-wrapper
    v-if="(data?.results ?? []).length"
    section
    :use-low-priority-title="isCatalog"
    class="full-width top"
    remove-padding
  >
    <template #title>
      <div class="top-title">
        <div class="top-title__prefix">
          <icon-lightning />
          ТОП 10
        </div>
        Лучшее за неделю на FLEX
      </div>
    </template>
    <roulette-slider
      appendClass=" content-right control-normal"
      :items="data?.results ?? []"
      :is-fetching="isFetching"
      skeleton
      remove-movie-hover-popup
      hide-cards-status
      hide-cards-title
    />
  </v-wrapper>
</template>

<style lang="scss">
  $gradient-text: linear-gradient(
    185.5deg,
    rgba(221, 255, 124, 1) 33.9%,
    rgba(255, 255, 255, 0.62) 63.81%,
    rgba(146, 185, 34, 0.47) 80.21%
  );

  $gradient-card: linear-gradient(
    203.97deg,
    rgba(221, 255, 124, 1) 5.83%,
    rgba(255, 255, 255, 0.62) 73.67%,
    rgba(146, 185, 34, 0.47) 110.88%
  );

  .top {
    padding: 56px 0 0;
    counter-reset: section;
    &-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 12px;
      font-size: 52px;
      line-height: 60px;
      @media (max-width: $retina) {
        font-size: 24px;
        line-height: 30px;
      }
      &__prefix {
        display: flex;
        align-items: center;
        gap: 0 12px;
        background: $gradient-text;
        background-clip: text;
        color: transparent;
        @media (max-width: $retina) {
          gap: 0 8px;
        }
        svg {
          width: 34px;
          height: 39px;
          @media (max-width: $retina) {
            width: 20px;
            height: 22px;
          }
        }
      }
    }
    @media (max-width: $retina) {
      padding: 24px 0;
    }
    .swiper-slide {
      position: relative;
      padding: 16px 16px 16px 40px;
      border-image-width: 2px;
      border-image-slice: 1%;
      border-image-repeat: round;
      background-color: $main_black;
      border-radius: 44px;
      margin-left: 2px;
      margin-bottom: 2px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% + 2px);
        left: 50%;
        transform: translate(-50%, calc(-50% - 1px));
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: $gradient-card;
        border-radius: 44px;
        z-index: -1;
      }
      &::after {
        counter-increment: section;
        content: counter(section);
        color: $main-black;
        position: absolute;
        padding-right: 25px;
        padding-left: 12px;
        z-index: 1;
        top: 8px;
        left: 12px;
        font-family: Mont;
        font-size: 180px;
        font-style: italic;
        font-weight: 700;
        line-height: 230.04px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        font-size: 180px;
        font-weight: 800;
        line-height: 230px;
        text-align: center;
        background: $gradient-card;
        background-clip: text;
        -webkit-text-stroke: 20px transparent;
      }

      @media (max-width: $retina) {
        padding: 8px 8px 8px 24px;
        &::after {
          top: 12px;
          left: 6px;
          font-family: Mont;
          font-size: 80px;
          line-height: 102.24px;
          -webkit-text-stroke: 10px transparent;
        }
      }
    }
  }
</style>
