import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "34",
  height: "39",
  viewBox: "0 0 34 39",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<mask id=\"path-1-inside-1_11220_49930\" fill=\"white\"><path d=\"M0 23.7082L20.8333 0.791504V15.3748H33.3333L12.5 38.2915V23.7082H0Z\"></path></mask><path d=\"M0 23.7082L20.8333 0.791504V15.3748H33.3333L12.5 38.2915V23.7082H0Z\" fill=\"#151A2B\"></path><path d=\"M0 23.7082L-22.9381 2.85531L-70.0771 54.7082H0V23.7082ZM20.8333 0.791504H51.8333V-79.3933L-2.10481 -20.0614L20.8333 0.791504ZM20.8333 15.3748H-10.1667V46.3748H20.8333V15.3748ZM33.3333 15.3748L56.2715 36.2277L103.41 -15.6252H33.3333V15.3748ZM12.5 38.2915H-18.5V118.476L35.4381 59.1444L12.5 38.2915ZM12.5 23.7082H43.5V-7.29183H12.5V23.7082ZM22.9381 44.561L43.7715 21.6444L-2.10481 -20.0614L-22.9381 2.85531L22.9381 44.561ZM-10.1667 0.791504V15.3748H51.8333V0.791504H-10.1667ZM20.8333 46.3748H33.3333V-15.6252H20.8333V46.3748ZM10.3952 -5.47802L-10.4381 17.4386L35.4381 59.1444L56.2715 36.2277L10.3952 -5.47802ZM43.5 38.2915V23.7082H-18.5V38.2915H43.5ZM12.5 -7.29183H0V54.7082H12.5V-7.29183Z\" fill=\"url(#paint0_linear_11220_49930)\" mask=\"url(#path-1-inside-1_11220_49930)\"></path><filter id=\"filter0_b_11220_49930\" x=\"-27\" y=\"-26.2085\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feGaussianBlur in=\"BackgroundImageFix\" stdDeviation=\"13.5\"></feGaussianBlur><feComposite in2=\"SourceAlpha\" operator=\"in\" result=\"effect1_backgroundBlur_11220_49930\"></feComposite><feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_backgroundBlur_11220_49930\" result=\"shape\"></feBlend></filter><linearGradient id=\"paint0_linear_11220_49930\" x1=\"17\" y1=\"16.0415\" x2=\"12.9345\" y2=\"37.9426\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#DDFF7C\"></stop><stop offset=\"0.645833\" stop-color=\"white\" stop-opacity=\"0.62\"></stop><stop offset=\"1\" stop-color=\"#92B922\" stop-opacity=\"0.47\"></stop></linearGradient>", 5)
  ])))
}
export default { render: render }