<script setup lang="ts">
  interface IProps {
    size?: 'extra' | 'large' | 'middle' | 'small';
    media?: 'large' | 'middle' | 'small';
    img?: boolean;
    link?: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    size: 'middle',
  });
</script>
<template>
  <component
    :is="props.link ? 'router-link' : 'div'"
    :data-size="props.size"
    :data-media="props.media"
    class="logo"
    to="/"
  >
    <img v-if="props.img" src="https://vhjh3saedm.a.trbcdn.net/logo.png" alt="kinoflex.ru" />
    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20" fill="none">
      <path
        d="M1.72858 19.511C0.773965 19.511 0 18.7627 0 17.8398V2.95961C0 1.59524 1.14396 0.489258 2.55518 0.489258H11.6504C12.4343 0.489258 13.0698 1.10363 13.0698 1.86154C13.0698 2.61944 12.4343 3.23382 11.6504 3.23382H3.45716V8.80447H8.80516C9.56584 8.80447 10.1824 9.40057 10.1824 10.136C10.1824 10.8714 9.56584 11.4675 8.80516 11.4675H3.45716V17.8398C3.45716 18.7627 2.6832 19.511 1.72858 19.511Z"
        fill="#757981"
      />
      <path
        d="M31.0761 19.511C29.6649 19.511 28.5209 18.405 28.5209 17.0406V2.16045C28.5209 1.23753 29.2949 0.489258 30.2495 0.489258C31.2041 0.489258 31.9781 1.23753 31.9781 2.16045V16.1908C31.9781 16.4638 32.2068 16.6849 32.4891 16.6849H39.9886C40.7958 16.6849 41.4502 17.3176 41.4502 18.0979C41.4502 18.8783 40.7958 19.511 39.9886 19.511H31.0761Z"
        fill="#757981"
      />
      <path
        d="M58.9108 19.511C57.4996 19.511 56.3556 18.405 56.3556 17.0406V2.95961C56.3556 1.59524 57.4996 0.489258 58.9108 0.489258H68.1465C68.9304 0.489258 69.5659 1.10363 69.5659 1.86154C69.5659 2.61944 68.9304 3.23382 68.1465 3.23382H60.0683C59.7862 3.23382 59.5573 3.45492 59.5573 3.72789V16.2724C59.5573 16.5453 59.7862 16.7664 60.0683 16.7664H68.1465C68.9304 16.7664 69.5659 17.3808 69.5659 18.1387C69.5659 18.8966 68.9304 19.511 68.1465 19.511H58.9108Z"
        fill="#757981"
      />
      <path
        d="M86.6049 19.511C85.3566 19.511 84.6131 18.1649 85.3076 17.1619L90.4547 9.72838L85.639 2.87562C84.9261 1.86129 85.6778 0.489258 86.9462 0.489258C87.4705 0.489258 87.9606 0.740741 88.2547 1.16021L92.5628 7.30991L96.9398 1.13451C97.226 0.730611 97.6997 0.489258 98.2062 0.489258C99.4388 0.489258 100.169 1.823 99.475 2.80818L94.6427 9.67404L99.7288 17.0908C100.439 18.1271 99.6708 19.511 98.3848 19.511C97.839 19.511 97.3302 19.244 97.0328 18.8013L92.5066 12.0653L87.9098 18.8304C87.621 19.2551 87.1307 19.511 86.6049 19.511Z"
        fill="#757981"
      />
      <path
        d="M68.7349 9.49424C69.2174 9.78426 69.2174 10.4631 68.7349 10.7531L63.4794 13.9122C62.9689 14.2193 62.3081 13.8643 62.3081 13.283V6.96435C62.3081 6.38308 62.9689 6.02809 63.4794 6.33516L68.7349 9.49424Z"
        fill="#757981"
      />
    </svg>
  </component>
</template>
<style lang="scss">
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    &[data-size='small'] {
      svg,
      img {
        width: 70px;
        height: 14px;
      }
    }
    &[data-size='middle'] {
      svg,
      img {
        width: 106px;
        height: 24px;
      }
    }
    &[data-size='large'] {
      svg,
      img {
        width: 140px;
        height: 30px;
      }
    }
    &[data-size='extra'] {
      svg,
      img {
        width: 244px;
        height: 56px;
      }
    }
    &[data-media='small'] {
      @media (max-width: $retina) {
        svg,
        img {
          width: 70px;
          height: 14px;
        }
      }
    }
    &[data-media='middle'] {
      @media (max-width: $retina) {
        svg,
        img {
          width: 100px;
          height: 20px;
        }
      }
    }
    &[data-media='large'] {
      @media (max-width: $retina) {
        svg,
        img {
          width: 140px;
          height: 30px;
        }
      }
    }
    @media (max-width: 430px) {
      svg,
      img {
        height: 40px;
      }
    }
  }
</style>
